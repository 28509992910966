exports.components = {
  "component---src-components-templates-article-template-article-template-js": () => import("./../../../src/components/templates/ArticleTemplate/ArticleTemplate.js" /* webpackChunkName: "component---src-components-templates-article-template-article-template-js" */),
  "component---src-components-templates-ev-landing-page-ev-landing-page-js": () => import("./../../../src/components/templates/EVLandingPage/EVLandingPage.js" /* webpackChunkName: "component---src-components-templates-ev-landing-page-ev-landing-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */)
}

